import { createContext } from 'react';

const ToastContext = createContext({
/**
 * {Object} Toast state parameters 
 *
 * @param {Bool}   show         - if the toast element is visible
 * @param {String} content      - the content displayed in the toast element (required)
 * @param {Number} duration     - the time (in milliseconds) (optional)
 * @param {Bool}   isError      - if the toast is of type 'error' (optional)
 */
});

export default ToastContext;