import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@shopify/polaris';
import { PageWrapper } from '../components/PageWrapper';

import Multipass from '../components/Settings/Multipass';
import Installation from '../components/Settings/Installation';
import { Configuration } from '../components/Settings/Configuration';
import DevelopmentStoreBanner from '../components/DevelopmentStoreBanner';
import ShopifyPlanBanner from '../components/ShopifyPlanBanner';

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('settings.title')} hasFooter fullWidth>
      <Layout>
        <DevelopmentStoreBanner />
        <ShopifyPlanBanner />
        <Layout.AnnotatedSection
          title={t('settings.multipass.title')}
          description={t('settings.multipass.description')}
        >
          <Multipass />
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t('settings.installation.title')}
          description={t('settings.installation.description')}
        >
          <Installation />
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t('settings.configuration.title')}
          description={t('settings.configuration.description')}
        >
          <Configuration />
        </Layout.AnnotatedSection>
      </Layout>
    </PageWrapper>
  );
};
