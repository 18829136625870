import { TextContainer, TextField, ChoiceList } from '@shopify/polaris';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const EditForm = ({ data, onPropertyUpdated, invalidFieldMessages }) => {
  const { t } = useTranslation();

  const handleCreateAccountChange = useCallback(
    (value) => onPropertyUpdated('allowCreateAccount', value[0] === 'yes'),
    [onPropertyUpdated],
  );

  const handleCartSwitchChange = useCallback(
    (value) => onPropertyUpdated('clearCartAfterSwitch', value[0] === 'yes'),
    [onPropertyUpdated],
  );

  return (
    <React.Fragment>
      <TextContainer spacing="tight">
        <div>
          <ChoiceList
            title={t('settings.configuration.form.createAccounts.title')}
            choices={[
              { label: t('generic.yes'), value: 'yes' },
              { label: t('generic.no'), value: 'no' },
            ]}
            selected={data.allowCreateAccount ? ['yes'] : ['no']}
            onChange={handleCreateAccountChange}
          />
        </div>
        <div>
          <ChoiceList
            title={t('settings.configuration.form.clearCartAfterSwitch.title')}
            choices={[
              { label: t('generic.yes'), value: 'yes' },
              { label: t('generic.no'), value: 'no' },
            ]}
            selected={data.clearCartAfterSwitch ? ['yes'] : ['no']}
            onChange={handleCartSwitchChange}
          />
        </div>
        <div>
          <TextField
            label={
              <label className="settings-fields-label">
                {t('settings.configuration.form.redirectUrl.title')}
              </label>
            }
            helpText={t('settings.configuration.form.redirectUrl.helpText')}
            value={data.redirectUrl}
            onChange={(newValue) => onPropertyUpdated('redirectUrl', newValue)}
            error={invalidFieldMessages['redirectUrl']}
          />
        </div>
      </TextContainer>
    </React.Fragment>
  );
};
