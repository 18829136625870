import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Layout } from '@shopify/polaris';
import { config } from '../config';
import { withFirebase } from '../firebase';
import ToastContext from '../contexts/ToastContext/ToastContext';
import ModalContext from '../contexts/ModalContext/ModalContext';
import { PageWrapper } from '../components/PageWrapper';
import { Loading } from '../components/AgentDetail/Loading';
import { PromoteToAgent } from '../components/AgentDetail/PromoteToAgent';
import { Info } from '../components/AgentDetail/Info';
import { Intro } from '../components/AgentDetail/Intro';
import Overview from '../components/AgentDetail/Overview';
import DevelopmentStoreBanner from '../components/DevelopmentStoreBanner';
import ShopifyPlanBanner from '../components/ShopifyPlanBanner';

const AgentDetail = ({ firebase }) => {
  const { dispatchToast } = useContext(ToastContext);
  const { dispatchModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [agentData, setAgentData] = useState({});
  const [isSuperAgent, setIsSuperAgent] = useState(false);
  const [agentDetailTitle, setAgentDetailTitle] = useState(t('agents.details'));

  const [agentActionLoading, setAgentActionLoading] = useState(false);

  const customerData = useCallback(async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      const queryParams = new URLSearchParams();
      queryParams.append('shop', firebase.userData.shop);
      const url = `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/customer/${id}?${queryParams}`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-account-switcher': firebase.userData.token,
        },
      };

      const data = await fetch(url, options);
      if (data.status !== 200) return;
      const customerDataResponse = await data.json();

      setAgentDetailTitle(customerDataResponse.firstName + ' ' + customerDataResponse.lastName);
      setIsAgent(
        customerDataResponse.tags.includes(config.agentTag) ||
          customerDataResponse.tags.includes(config.legacy.agentTag),
      );
      setIsSuperAgent(
        customerDataResponse.tags.includes(config.superAgentTag) ||
          customerDataResponse.tags.includes(config.legacy.superAgentTag),
      );

      setAgentData(customerDataResponse);
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [id, firebase]);

  useEffect(() => {
    customerData();
  }, [customerData]);

  const makeAgent = useCallback(async () => {
    try {
      setAgentActionLoading(true);

      const url = `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/customer/${id}/agent/assign`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-account-switcher': firebase.userData.token,
        },
        body: JSON.stringify({
          shop: firebase.userData.shop,
          customerId: `gid://shopify/Customer/${id}`,
        }),
      };

      const data = await fetch(url, options);
      if (data.status !== 200) throw Error('Something went wrong');

      setIsAgent(true);
      dispatchToast(t('agents.successAgent'));
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setAgentActionLoading(false);
    }
  }, [firebase.userData, id, t, dispatchToast]);

  const confirmAndRevokeAgentRights = () => {
    dispatchModal(true, {
      title: t('agents.revoke.title'),
      message: t('agents.revoke.confirmMessage'),
      primaryAction: () => revokeAgentRights(),
      primaryActionLabel: t('agents.revoke.submit'),
      isPrimaryDestructive: false,
    });
  };
  const revokeAgentRights = useCallback(async () => {
    try {
      setAgentActionLoading(true);
      dispatchModal(false);

      const url = `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/customer/${id}/agent/unassign`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-account-switcher': firebase.userData.token,
        },
        body: JSON.stringify({
          shop: firebase.userData.shop,
          customerId: `gid://shopify/Customer/${id}`,
        }),
      };

      const data = await fetch(url, options);
      if (data.status !== 200) throw Error('Something went wrong');

      setIsAgent(false);
      setIsSuperAgent(false);
      dispatchToast(t('agents.successRevokeAgent'));
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setAgentActionLoading(false);
    }
  }, [firebase.userData, id, t, dispatchToast, dispatchModal]);

  const revokeSuperAgentRights = useCallback(async () => {
    try {
      setAgentActionLoading(true);

      const url = `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/customer/${id}/superagent/unassign`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-account-switcher': firebase.userData.token,
        },
        body: JSON.stringify({
          shop: firebase.userData.shop,
          customerId: `gid://shopify/Customer/${id}`,
        }),
      };

      const data = await fetch(url, options);
      if (data.status !== 200) throw Error('Something went wrong');

      setIsSuperAgent(false);
      dispatchToast(t('agents.successRevokeSuperAgent'));
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setAgentActionLoading(false);
    }
  }, [firebase.userData, id, t, dispatchToast]);

  const makeSuperAgent = useCallback(async () => {
    try {
      setAgentActionLoading(true);

      const url = `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/customer/${id}/superagent/assign`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-account-switcher': firebase.userData.token,
        },
        body: JSON.stringify({
          shop: firebase.userData.shop,
          customerId: `gid://shopify/Customer/${id}`,
        }),
      };

      const data = await fetch(url, options);
      if (data.status !== 200) throw Error('Something went wrong');

      setIsSuperAgent(true);
      dispatchToast(t('agents.successSuperAgent'));
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setAgentActionLoading(false);
    }
  }, [firebase.userData, id, t, dispatchToast]);

  return (
    <PageWrapper title={agentDetailTitle} hasFooter fullWidth>
      <Layout>
        <DevelopmentStoreBanner />
        <ShopifyPlanBanner />
        {isLoading ? (
          <Loading />
        ) : isAgent ? (
          <React.Fragment>
            <Layout.Section>
              <Intro
                agent={agentData}
                actions={{ submit: isSuperAgent ? revokeSuperAgentRights : makeSuperAgent }}
                isSuperAgent={isSuperAgent}
                isLoading={agentActionLoading}
              />
              {!isSuperAgent && <Overview firebase={firebase} agentId={id} agent={agentData} />}
            </Layout.Section>
            <Layout.Section secondary>
              <Info
                agent={agentData}
                actions={{ submit: confirmAndRevokeAgentRights }}
                isLoading={agentActionLoading}
              />
            </Layout.Section>
          </React.Fragment>
        ) : (
          <Layout.Section>
            <PromoteToAgent
              agent={agentData}
              isLoading={agentActionLoading}
              hasError={hasError}
              actions={{ submit: makeAgent }}
            />
          </Layout.Section>
        )}
      </Layout>
    </PageWrapper>
  );
};

export default withFirebase(AgentDetail);
