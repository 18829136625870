import React, { useState } from 'react'
import ModalContext from './ModalContext';

const ModalProvider = ({ children }) => {
  const initialState = {
    open: false,
    title: '',
    message: '',
    primaryAction: null,
    primaryActionLabel: '',
    isPrimaryDestructive: false,
    secondaryActionLabel: ''
  };

  const [modalState, setModalState] = useState(initialState);

  const dispatchModal = (open, {
    title = initialState.title,
    message = initialState.message,
    primaryAction = initialState.primaryAction,
    primaryActionLabel = initialState.primaryActionLabel,
    isPrimaryDestructive = initialState.isPrimaryDestructive,
    secondaryActionLabel = initialState.secondaryActionLabel
  } = {}) => {
    // check if required params are not null | undefined | empty string, otherwise throw error.
    switch (true) {
      case (open && !title):
        throw new Error('modal title param [string] is required.');
      case (open && !message):
        throw new Error('modal message param [string] is required.');
      default:
        break;
    }
    if (open) {
      setModalState({
        ...modalState,
        open: false,
      });
    }

    const newModalState = {
      open: open,
      title: title,
      message: message,
      primaryAction: primaryAction,
      primaryActionLabel: primaryActionLabel,
      isPrimaryDestructive: isPrimaryDestructive,
      secondaryActionLabel: secondaryActionLabel
    };

    return setModalState(newModalState);
  };

  return (
    <ModalContext.Provider value={{
      modalState,
      setModalState,
      dispatchModal
    }}>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;