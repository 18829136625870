import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@shopify/polaris';
import { PageWrapper } from '../components/PageWrapper';

import { Introduction } from '../components/Agents/Introduction';
import Overview from '../components/Agents/Overview';
import DevelopmentStoreBanner from '../components/DevelopmentStoreBanner';
import ShopifyPlanBanner from '../components/ShopifyPlanBanner';

export const Agents = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('agents.title')} hasFooter fullWidth>
      <Layout>
        <DevelopmentStoreBanner />
        <ShopifyPlanBanner />
        <Layout.Section>
          <Introduction />
        </Layout.Section>
        <Layout.Section>
          <Overview agentsOnly={true} />
        </Layout.Section>
      </Layout>
    </PageWrapper>
  );
};
