import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Card, EmptyState, Layout, Link } from '@shopify/polaris';
import { PageWrapper } from '../components/PageWrapper';
import DevelopmentStoreBanner from '../components/DevelopmentStoreBanner';
import ShopifyPlanBanner from '../components/ShopifyPlanBanner';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('pageNotFound.title')} hasFooter fullWidth>
      <Layout>
        <DevelopmentStoreBanner />
        <ShopifyPlanBanner />
        <Layout.Section>
          <Card sectioned>
            <EmptyState
              heading={t('pageNotFound.heading')}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
              <p>
                <Trans i18nKey="pageNotFound.message">
                  The page you’re looking for couldn’t be found. Check the web address and try
                  again, or
                  <Link url="/">go back to app home</Link>
                </Trans>
              </p>
            </EmptyState>
          </Card>
        </Layout.Section>
      </Layout>
    </PageWrapper>
  );
};
