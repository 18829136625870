import {
  Card,
  Layout,
  TextContainer,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';
import React from 'react';

export const Loading = () => {
  return (
    <React.Fragment>
      <Layout.Section>
        <Card>
          <Card.Section>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </TextContainer>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={20} />
            </TextContainer>
          </Card.Section>
        </Card>
      </Layout.Section>
      <Layout.Section secondary>
        <Card>
          <Card.Section>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={8} />
            </TextContainer>
          </Card.Section>
        </Card>
      </Layout.Section>
    </React.Fragment>
  );
};
