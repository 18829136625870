import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Agents } from '../../pages/Agents';
import AgentDetail from '../../pages/AgentDetail';
import { ManageAgents } from '../../pages/ManageAgents';
import { Settings } from '../../pages/Settings';

import { PageNotFound } from '../../pages/PageNotFound';

export const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Agents} />
      <Route exact path="/manage" component={Agents} />
      <Route exact path="/manage/agents" component={ManageAgents} />
      <Route exact path="/manage/agents/:id" component={AgentDetail} />
      <Route exact path="/manage/settings" component={Settings} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};
