import React, { useState } from 'react'
import ToastContext from './ToastContext';

const ToastProvider = ({ children }) => {
  const initialState = {
    show: false,
    content: '',
    duration: 3000,
    isError: false
  };

  const [toastState, setToastState] = useState(initialState);

  const dispatchToast = (content, { duration = 3000, isError = false } = { }) => {
    // check if content param is not null | undefined | empty string, otherwise throw error (required param).
    if (!content) {
      throw new Error('toast content param [string] is required.');
    }

    const newToastState = {
      show: true,
      content: content,
      duration: duration,
      isError: isError
    };

    return setToastState(newToastState);
  };

  return (
    <ToastContext.Provider value={{
      toastState: toastState,
      setToastState: setToastState,
      dispatchToast: dispatchToast
    }}>
      {children}
    </ToastContext.Provider>
  );
}

export default ToastProvider;