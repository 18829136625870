import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@shopify/polaris';
import { PageWrapper } from '../components/PageWrapper';

import { ManageIntroduction } from '../components/Agents/ManageIntroduction';
import Overview from '../components/Agents/Overview';
import DevelopmentStoreBanner from '../components/DevelopmentStoreBanner';
import ShopifyPlanBanner from '../components/ShopifyPlanBanner';

export const ManageAgents = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('agents.title')} hasFooter fullWidth>
      <Layout>
        <DevelopmentStoreBanner />
        <ShopifyPlanBanner />
        <Layout.Section>
          <ManageIntroduction />
        </Layout.Section>
        <Layout.Section>
          <Overview agentsOnly={false} />
        </Layout.Section>
      </Layout>
    </PageWrapper>
  );
};
