import React from 'react';
import { Stack, Button } from '@shopify/polaris';
import { InfoMinor } from '@shopify/polaris-icons';

export const ActionsWithHelpLink = ({ actions, infoText, infoLink }) => {
  return (
    <Stack alignment="center">
      <Stack.Item fill>
        {infoLink && (
          <Button icon={InfoMinor} plain children={infoText} external={true} url={infoLink} />
        )}
      </Stack.Item>
      <Stack.Item>{actions}</Stack.Item>
    </Stack>
  );
};
