import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withFirebase } from '../firebase';
import { Banner, TextContainer, Layout } from '@shopify/polaris';

const DevelopmentStoreBanner = ({ firebase }) => {
  const { t } = useTranslation();
  const [isDevelopment, setIsDevelopment] = useState(false);

  useEffect(() => {
    async function checkState() {
      const shop = await firebase.shop(firebase.auth.currentUser.uid).get();
      const shopData = shop.data();

      setIsDevelopment((shopData.metaData || { planName: 'unknown' })?.planName === 'affiliate');
    }

    checkState();
  }, [firebase]);

  // return null;
  if (!isDevelopment) return null;

  return (
    <Layout.Section>
      <Banner status="info" title={t('development.title')}>
        <TextContainer spacing="tight">{t('development.description')}</TextContainer>
      </Banner>
    </Layout.Section>
  );
};

export default withFirebase(DevelopmentStoreBanner);
