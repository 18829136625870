export const nl = {
  generic: {
    authorizing: 'Authenticeren',
    hide: 'Verbergen',
    show: 'Toon',
    edit: 'Wijzig',
    cancel: 'Annuleren',
    save: 'Opslaan',
    saveSuccess: 'Instellingen succesvol opgeslagen',
    goBack: 'Ga terug',
    yes: 'Ja',
    no: 'Nee',
    errors: {
      problemLoading: 'Er was een probleem met het laden van deze pagina',
      problemLoadingItem: 'Er ging iets niet goed met het laden van {{item}}',
      technicalProblem:
        'Een technisch probleem zorgt ervoor dat de pagina goed kon worden geladen.',
      doesNotExist:
        'Het lijkt erop dat het {{item}} dat je opvroeg niet meer bestaat, of er is een technisch probleem.',
      thingsToTry: 'Hier volgen een aantal suggesties:',
      tryAnotherItem:
        'Ga terug en kies een ander {{item}} (om uit te sluiten dat er verbindingsproblemen zijn)',
      reloadPage: 'Vernieuw de pagina',
      comeBack: 'Probeer het over een paar minuten nogmaals',
      serverError: 'Server fout',
      networkError: 'Netwerk fout',
      contactSupport:
        'Als het probleem zich blijft voordoen, neem dan contact op met <1>support</1>.',
    },
    form: {
      validation: {
        fieldRequired: 'Dit veld is verplicht',
        fieldHasSpaces: 'Spaties zijn niet toegestaan',
        fieldIsNotNumeric: 'Alleen cijfers zijn toegestaan',
      },
    },
    pagination: {
      page: 'Pagina',
      of: 'va',
      itemsPerPage: 'Items per pagina',
    },
  },
  dashboard: {
    title: 'Dashboard',
  },
  footer: {
    linkLearnMore: 'Meer informatie over Returnify',
  },
};
