import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Badge, Button, ButtonGroup, Card, Form, Link, Stack } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { withFirebase } from '../../../firebase';
import { AppConfigContext } from '../../../contexts/AppConfigContext/AppConfigContext';
import { ActionsWithHelpLink } from '../../Help/ActionsWithHelpLink';
import { AppConfig } from '../../../types/AppConfig';
import { EditForm } from './EditForm';
import { Display } from './Display';

const Multipass = ({ firebase }) => {
  const { t } = useTranslation();
  const { config, saveConfigToDb } = useContext(AppConfigContext);

  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [invalidFieldMessages, setInvalidFieldMessages] = useState({});

  const [multipassData, setMultipassData] = useState();
  const [hasToken, setHasToken] = useState(true);
  const [draftMultipassData, setDraftMultipassData] = useState();

  const enterEditMode = useCallback(() => {
    setDraftMultipassData({ multipassToken: '' });
    setEditMode(true);
  }, []); // addressData

  const exitEditMode = useCallback(() => {
    setEditMode(false);
    setInvalidFieldMessages({});
  }, []);

  const handlePropertyUpdated = useCallback((key, value) => {
    setDraftMultipassData((currentData) => ({
      ...currentData,
      [key]: value,
    }));

    // reset validation
    setInvalidFieldMessages({});
  }, []);

  const handleFormValidation = useCallback(() => {
    // validate form fields
    let validation = {};

    const required = ['multipassToken'];

    required.forEach((key) => {
      let validationMessage = null;

      if (draftMultipassData[key] === '') validationMessage = t('generic.form.fieldRequired');

      // check if empty
      if (validationMessage) {
        validation = {
          ...validation,
          [key]: validationMessage,
        };
      }
    });

    // set validation to state
    setInvalidFieldMessages(validation);

    // return true if form invalid
    if (Object.keys(validation).length > 0 && validation.constructor === Object) {
      return true;
    } else {
      return false;
    }
  }, [draftMultipassData, setInvalidFieldMessages, t]);

  const handleSubmit = useCallback(async () => {
    if (handleFormValidation()) {
      return;
    }

    setIsSaving(true);
    try {
      const saveSuccess = await saveConfigToDb(AppConfig.Generic.id, {
        multipassToken: draftMultipassData.multipassToken,
      });
      if (saveSuccess) {
        setEditMode(false);
        setHasToken(true);
        setMultipassData({
          multipassToken: draftMultipassData.multipassToken,
        });
        exitEditMode();
      }
    } finally {
      setIsSaving(false);
    }
  }, [handleFormValidation, draftMultipassData, exitEditMode, saveConfigToDb]);

  useEffect(() => {
    if (config[AppConfig.Generic.id]) {
      setMultipassData({ multipassToken: config[AppConfig.Generic.id]?.multipassToken });
      if (!config[AppConfig.Generic.id].multipassToken) setHasToken(false);
    }
  }, [config]);

  useEffect(() => {
    if (hasToken === false) enterEditMode();
  }, [hasToken, enterEditMode]);

  return (
    <Form preventDefault onSubmit={handleSubmit}>
      <Card>
        <Card.Header title={t('settings.multipass.cardTitle')}>
          <Stack>
            {editMode ? (
              <Link url={`https://${firebase.userData.shop}/admin/settings/checkout`} external>
                {t('settings.multipass.cardLink')}
              </Link>
            ) : (
              <Badge status="success">{t('generic.active')}</Badge>
            )}
          </Stack>
        </Card.Header>
        <Card.Section>
          {editMode ? (
            <EditForm
              data={draftMultipassData}
              onPropertyUpdated={handlePropertyUpdated}
              invalidFieldMessages={invalidFieldMessages}
            />
          ) : (
            <Display data={multipassData} />
          )}
        </Card.Section>
        <Card.Section subdued>
          {editMode ? (
            <ActionsWithHelpLink
              actions={
                <ButtonGroup>
                  <Button
                    children={t('generic.cancel')}
                    onClick={exitEditMode}
                    disabled={isSaving || !hasToken}
                  />
                  <Button primary submit children={t('generic.save')} loading={isSaving} />
                </ButtonGroup>
              }
            />
          ) : (
            <ActionsWithHelpLink
              actions={
                <Button children={t('settings.multipass.replaceSecret')} onClick={enterEditMode} />
              }
            />
          )}
        </Card.Section>
      </Card>
    </Form>
  );
};

export default withFirebase(Multipass);
