import React, { useEffect } from 'react';
import { useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { Redirect } from '@shopify/app-bridge/actions';
import { useTranslation } from 'react-i18next';
import { withFirebase } from '../../firebase';
import { AuthError } from './AuthError';
import { useAuthorizeCallback } from './authorize_hooks';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { AppConfigProvider } from '../../contexts/AppConfigContext/AppConfigProvider';
import { AppRoutes } from '../Routing/AppRoutes';

const Authorize = ({ firebase, shop }) => {
  const app = useAppBridge();
  const { t } = useTranslation();

  const { initialized, errorCode, redirectUrl, handleAuthorize } = useAuthorizeCallback(
    firebase,
    app,
    shop,
    getSessionToken,
  );

  // redirect if url found
  if (redirectUrl) {
    const adminPath = redirectUrl.replace(`https://${shop}/admin`, ``);
    const redirect = Redirect.create(app);
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: adminPath,
    });
  }

  useEffect(() => {
    handleAuthorize();
  }, [handleAuthorize]);

  const initializedMarkup = !errorCode ? (
    <AppConfigProvider firebase={firebase}>
      <AppRoutes firebase={firebase} />
    </AppConfigProvider>
  ) : (
    <AuthError errorCode={errorCode} />
  );

  return initialized ? initializedMarkup : <LoadingSpinner text={t('generic.authorizing')} />;
};

export default withFirebase(Authorize);
