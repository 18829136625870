import React from 'react';
import { RoutePropagator } from '@shopify/app-bridge-react';
import { withRouter } from 'react-router-dom';

/**
 * This component is used to synchronize the Shopify embedded app's URL with the parent page
 * -----
 * More info here: https://shopify.dev/tools/app-bridge/react-components/route-propagator
 */

const ClientRoutePropagator = (props) => {
  const { location } = props;

  return <RoutePropagator location={location} />;
};

export default withRouter(ClientRoutePropagator);
