import { useCallback, useEffect, useState } from 'react';

export const useAppApiKey = (shop) => {
  const [loaded, setLoaded] = useState(false);
  const [appApiKey, setAppApiKey] = useState(undefined);

  const fetchAppApiKey = useCallback(async () => {
    try {
      // Prepare login fetch request
      const fetchUrl = `${process.env.REACT_APP_CLOUD_US_FUNCTIONS_URI}/shopifyApp/resolveAPIKey?shop=${shop}`;
      const fetchOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) throw new Error(response.status);
      const responseBody = await response.json();

      if (!responseBody.apiKey) throw new Error('No API key provided');
      setAppApiKey(responseBody.apiKey);
    } catch (error) {
      console.error('Error while fetching SHopify API key', error);
      setAppApiKey(undefined);
    } finally {
      setLoaded(true);
    }
  }, [shop]);

  // eslint-disable-next-line
  useEffect(() => fetchAppApiKey(), []);

  return [loaded, appApiKey];
};
