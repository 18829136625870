import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Set Firebase config variables
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

class Firebase {
  constructor(incognitoMode) {
    if (!app.apps.length) {
      app.initializeApp(config);
    }

    // Firebase APIs
    this.auth = app.auth();
    this.db = app.firestore();
    this.fieldValue = app.firestore.FieldValue;

    // determine the persistence mode based on browser mode
    if (incognitoMode === false) {
      this.authPersistence = app.auth.Auth.Persistence.SESSION;
    } else {
      this.authPersistence = app.auth.Auth.Persistence.NONE;
    }

    // User data
    this.userData = {
      app: '-',
      userId: '-',
      email: '',
      name: '-',
      shop: '-',
      installedAt: '-',
      storefrontApiKey: '',
      currency: '',
      productsImported: false,
    };

    // shop data
    this.shopData = {};
  }

  // User data store API
  setUserData = (idTokenResult, installedAt, currency, storefrontApiKey, productsImported) => {
    this.userData = {
      token: idTokenResult.token,
      id: idTokenResult.id,
      app: idTokenResult.claims.aud,
      shop: idTokenResult.claims.sfy_shop,
      userId: idTokenResult.claims.sfy_user.id,
      email: '',
      installedAt,
      storefrontApiKey,
      currency,
      productsImported,
    };
  };

  setShopData = (shop) => {
    this.shopData = shop;
  };

  // Authenticate with firebase
  authenticate = async (customToken) => {
    try {
      await this.auth.setPersistence(this.authPersistence);

      // Wait for the sign in to firebase using the received custom token
      await this.auth.signInWithCustomToken(customToken);

      // Wait for the user data
      const idTokenResult = await this.auth.currentUser.getIdTokenResult();

      // We need to also get the storefront api key and save it to the user data store
      let storefrontApiKey, currency, installedAt, productsImported;
      const shopDoc = await this.shop(idTokenResult.claims.sfy_shop).get();

      if (shopDoc.exists) {
        installedAt = shopDoc.data().installedAt.toDate();
        currency = shopDoc.data().metaData?.currency || 'EUR';
        storefrontApiKey = shopDoc.data().storefront?.access_token;
        productsImported = !(shopDoc.data().productSync?.busy || false);

        this.setShopData(shopDoc.data());
      }

      // Set the user data to data store
      this.setUserData(idTokenResult, installedAt, currency, storefrontApiKey, productsImported);

      return true;
    } catch (error) {
      console.error('Firebase auth error:', error);
      return false;
    }
  };

  // Shop API
  shop = (id) => this.db.doc(`sfyShops/${id}`);

  // Returns API
  rmaRequest = (id) => this.db.doc(`sfyShops/${this.userData.shop}/rmaRequests/${id}`);
  rmaRequests = () => this.db.collection(`sfyShops/${this.userData.shop}/rmaRequests`);

  // Shopify Orders API
  sfyOrder = (id) => this.db.doc(`sfyShops/${this.userData.shop}/sfyOrders/${id}`);

  // Reports API
  reports = () => this.db.collection(`sfyShops/${this.userData.shop}/reporting`);
  report = (id) => this.reports().doc(id);

  // App config API
  specificConfig = (id) => this.db.doc(`sfyShops/${this.userData.shop}/config/${id}`);
}

export default Firebase;
