import { useCallback, useState } from 'react';

export const useAuthorizeCallback = (firebase, app, shop, getSessionToken) => {
  const [initialized, setInitialized] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const handleAuthorize = useCallback(async () => {
    try {
      // Get session token from App Bridge
      const shopifyAuthToken = await getSessionToken(app);

      // Prepare login fetch request
      const fetchUrl = `${process.env.REACT_APP_CLOUD_US_FUNCTIONS_URI}/shopifyApp/session`;
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shop: shop,
          token: shopifyAuthToken,
        }),
      };

      // Execute login fetch
      const authResponse = await fetch(fetchUrl, fetchOptions);

      if (!authResponse.ok) throw new Error(authResponse.status);

      // Get response body
      const authResponseBody = await authResponse.json();

      switch (authResponseBody.status) {
        case 'ok':
        default:
          // Authenticate with firestore using a custom token
          const isAuthenticated = await firebase.authenticate(authResponseBody.token);
          if (!isAuthenticated) throw new Error('FIRESTORE');

          setInitialized(true);
          break;
        case 'redirect-to-billing':
          setRedirectUrl(authResponseBody.url);
          break;
      }
    } catch (error) {
      console.error('Error while authorizing:', error);
      setErrorCode(error);
      setInitialized(true);
    }
  }, [firebase, app, shop, getSessionToken]);

  return {
    initialized,
    errorCode,
    redirectUrl,
    handleAuthorize,
  };
};
