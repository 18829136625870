import { Subheading, TextContainer, TextStyle } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Display = ({ data }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TextContainer spacing="tight">
        <Subheading>{t('settings.multipass.display.label')}</Subheading>
        <p>
          <TextStyle variation="code">{data?.multipassToken || ''}</TextStyle>
        </p>
      </TextContainer>
    </React.Fragment>
  );
};
