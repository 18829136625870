import { createContext } from 'react';

const ModalContext = createContext({
/**
 * {Object} Modal state parameters 
 *
 * @param {Bool}      open                  - if the modal element is visible
 * @param {String}    title                 - the title of the modal window (required)
 * @param {String}    message               - the message in the modal window (required)
 * @param {Function}  primaryAction         - callback function to be triggered if there is a primary button (optional)
 * @param {String}    primaryActionLabel    - text string for the primary action button (optional)
 * @param {Bool}      isPrimaryDestructive  - if the primary action button is red (optional)
 * @param {String}    secondaryActionLabel  - text string for the secondary action button (optional)
 */
});

export default ModalContext;