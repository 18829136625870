import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withFirebase } from '../firebase';
import { Banner, TextContainer, Layout } from '@shopify/polaris';

const ShopifyPlanBanner = ({ firebase }) => {
  const { t } = useTranslation();
  const [shopifyPlanNotCompatible, setShopifyPlanNotCompatible] = useState(false);

  useEffect(() => {
    async function checkState() {
      const shop = await firebase.shop(firebase.auth.currentUser.uid).get();
      const shopData = shop.data();

      const compatiblePlans = ['affiliate', 'partner_test', 'plus_partner_sandbox', 'shopify_plus'];
      const currentPlan = (shopData.metaData || { planName: 'affiliate' })?.planName;

      if (!compatiblePlans.includes(currentPlan)) setShopifyPlanNotCompatible(true);
    }

    checkState();
  }, [firebase]);

  // return null;
  if (!shopifyPlanNotCompatible) return null;

  return (
    <Layout.Section>
      <Banner status="critical" title={t('shopifyPlan.title')}>
        <TextContainer spacing="tight">{t('shopifyPlan.description')}</TextContainer>
      </Banner>
    </Layout.Section>
  );
};

export default withFirebase(ShopifyPlanBanner);
