import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, FooterHelp, Link, Page, Stack } from '@shopify/polaris';
import { InfoMinor } from '@shopify/polaris-icons';
import { TitleBar } from '@shopify/app-bridge-react';

export const PageWrapper = ({ title, breadcrumbs, fullWidth, hasFooter, children }) => {
  const { t } = useTranslation();

  // set help text in the footer
  const footerMarkup = hasFooter && (
    <FooterHelp>
      <Stack spacing="extraTight" alignment="center">
        <Icon source={InfoMinor} color="highlight" />
        <Link url={''} external={true}>
          <span>{t('footer.linkLearnMore')}</span>
        </Link>
      </Stack>
    </FooterHelp>
  );

  return (
    <Page fullWidth={fullWidth}>
      <TitleBar
        title={title}
        breadcrumbs={breadcrumbs}
        secondaryActions={[
          {
            content: t('agents.title'),
            url: '/manage',
          },
          {
            content: t('settings.title'),
            url: '/manage/settings',
          },
        ]}
        primaryAction={{
          content: t('manage.title'),
          url: '/manage/agents',
        }}
      />
      {children}
      {footerMarkup}
    </Page>
  );
};
