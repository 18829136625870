import React from 'react';
import { withRouter } from 'react-router-dom';
import { ClientRouter as AppBridgeClientRouter } from '@shopify/app-bridge-react';

/**
 * This component prevents App Bridge from changing the iframe URL, and lets us provide a client-side router (react-router), to handle navigation.
 * -----
 * More info here: https://shopify.dev/tools/app-bridge/react-components/client-router
 */

const ClientRouter = ({ history }) => {
  return <AppBridgeClientRouter history={history} />;
};

export default withRouter(ClientRouter);
