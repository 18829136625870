import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppConfigContext } from './AppConfigContext';
import { AppConfig } from '../../types/AppConfig';
import ToastContext from '../ToastContext';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';

export const AppConfigProvider = ({ firebase, children }) => {
  const { dispatchToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const [appConfig, setAppConfig] = useState({});
  const [isFetchingConfig, setIsFetchingConfig] = useState(false);

  const updateConfigState = (configData, configId) =>
    setAppConfig((prevState) => {
      let newState = {};
      newState = {
        ...prevState,
        ...(configId
          ? {
              [configId]: {
                ...prevState[configId],
                ...configData,
              },
            }
          : configData),
      };

      return newState;
    });

  const fetchAppConfig = useCallback(async () => {
    const configKeys = Object.keys(AppConfig).map((key) => AppConfig[key].id);
    let configData = Object.keys(AppConfig).reduce((config, key) => {
      config[AppConfig[key].id] = AppConfig[key].defaults;
      return config;
    }, {});

    try {
      for (let i = 0; i < configKeys.length; i++) {
        const configDoc = await firebase.specificConfig(configKeys[i]).get();

        if (configDoc.exists) {
          configData[configKeys[i]] = {
            ...configData[configKeys[i]],
            ...configDoc.data(),
          };
        }
      }
    } catch (error) {
      console.error('Error fetching app config data:', error);
    } finally {
      updateConfigState(configData);
      setIsFetchingConfig(false);
    }
  }, [firebase]);

  // Fetch the initial data only once when the app is loaded
  // eslint-disable-next-line
  useEffect(() => fetchAppConfig(), []);

  const saveAppConfig = useCallback(
    async (configId, dataToSave) => {
      try {
        await firebase.specificConfig(configId).update(dataToSave);
        updateConfigState(dataToSave, configId);
        dispatchToast(t('generic.saveSuccess'));
        return true;
      } catch (error) {
        console.error('Error saving app config data:', error);
        dispatchToast(t('generic.errors.serverError'), { isError: true });
        return false;
      }
    },
    [dispatchToast, firebase, t],
  );

  return (
    <AppConfigContext.Provider
      value={{
        config: appConfig,
        saveConfigToDb: saveAppConfig,
      }}
    >
      {isFetchingConfig ? <LoadingSpinner text={t('generic.loading')} /> : children}
    </AppConfigContext.Provider>
  );
};
