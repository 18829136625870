import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EmptyState, Link, Page, TextContainer } from '@shopify/polaris';

export const AuthError = ({ errorCode }) => {
  const { t } = useTranslation();

  let errorHeading, errorMessage, errorAction;

  switch (errorCode) {
    // When app not installed
    case 401:
      errorHeading = t('generic.errors.appNotInstalled.heading');
      errorMessage = t('generic.errors.appNotInstalled.message');
      errorAction = {
        content: t('generic.errors.appNotInstalled.action'),
        onAction: () => window.location.replace('/oauth' + window.location.search),
      };
      break;
    // Other errors
    case 400:
    case 405:
    case 500:
    default:
      errorHeading = t('generic.errors.notAuthorized.heading');
      errorMessage = t('generic.errors.notAuthorized.message');
      errorAction = {
        content: t('generic.errors.notAuthorized.action'),
        onAction: () => window.location.reload(),
      };
  }

  return (
    <Page>
      <EmptyState
        heading={errorHeading}
        action={errorAction}
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        footerContent={
          <Trans i18nKey="generic.errors.contactSupport">
            If the problem persists, please contact
            <Link url="http://apps.support.code.nl/" external={true}>
              support
            </Link>
            .
          </Trans>
        }
      >
        <TextContainer>
          <p>{errorMessage}</p>
        </TextContainer>
      </EmptyState>
    </Page>
  );
};
