import { TextContainer, TextStyle } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Display = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <React.Fragment>
      <TextContainer spacing="tight">
        <p>
          <TextStyle variation="strong">
            {t('settings.configuration.form.createAccounts.title')}
          </TextStyle>
        </p>
        <p>
          <TextStyle>
            {data.allowCreateAccount === true ? t('generic.yes') : t('generic.no')}
          </TextStyle>
        </p>
        <p>
          <TextStyle variation="strong">
            {t('settings.configuration.form.clearCartAfterSwitch.title')}
          </TextStyle>
        </p>
        <p>
          <TextStyle>
            {data.clearCartAfterSwitch === true ? t('generic.yes') : t('generic.no')}
          </TextStyle>
        </p>
        <p>
          <TextStyle variation="strong">
            {t('settings.configuration.form.redirectUrl.title')}
          </TextStyle>
        </p>
        <p>
          <TextStyle>{data.redirectUrl || '-'}</TextStyle>
        </p>
      </TextContainer>
    </React.Fragment>
  );
};
