export const AppConfig = {
  Generic: {
    id: 'generic',
    defaults: {
      allowSwitchToAll: false,
      allowCreateAccount: false,
      appSecret: '',
      clearCartAfterSwitch: false,
      redirectUrl: '',
      multipassToken: '',
      snippetsInstalled: false,
    },
  },
};
