export const config = {
  customerTag: 'AS-ASSUMABLE-$X',
  agentTag: 'AS-AGENT',
  superAgentTag: 'AS-AGENT-ALL',
  legacy: {
    customerTag: 'IDS-ASSUMABLE-$X',
    agentTag: 'IDS-AGENT',
    superAgentTag: 'IDS-AGENT-ALL',
  },
};
