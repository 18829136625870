export const en = {
  generic: {
    loading: 'Loading',
    authorizing: 'Authorizing',
    active: 'Active',
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    moreInfo: 'More information',
    copyToClipboard: 'Copy to clipboard',
    copiedToClipboard: 'Copied to clipboard',
    saveSuccess: 'Settings successfully saved',
    form: {
      fieldRequired: 'This field is required',
    },
    errors: {
      serverError: 'Server error, please try again or refresh and try again.',
    },
  },
  development: {
    title: 'Free to use while running as Development store',
    description:
      'When you upgrade to a paid Shopify Plan you have to accept the Recurring charges for this app. This is automatically shown once you open this app after enabling a Shopify plan.',
  },
  shopifyPlan: {
    title: 'This store cannot work with this app',
    description:
      'This app only works on a Development store or on a Shopify Plus store. We recommend to upgrade your plan or to uninstall the app to avoid further costs.',
  },
  agents: {
    title: 'Agents',
    details: 'Agent',
    introduction:
      'Agents can switch between identities. An agent can assume the identity of the <1>assigned customers</1>. You can also make this agent a Super agent, then all customers can be assumed (other agents cannot be assumed).',
    manageTitle: 'Manage agents',
    manageIntroduction: 'You can create an agent by assigning the agent role to a customer.',
    singular: 'agent',
    plural: 'agents',
    loadingData: 'Loading data',
    superAgent: 'Super agent',
    agent: 'Agent',
    error: 'Something went wrong while fetching the data. Please refresh this page to try again.',
    promote: {
      title: '{{name}} is not an agent yet',
      description:
        'In order to assign customers you will have to make <strong>{{name}}</strong> an agent. This will add a tag to the customer which indicates that his customer may assume other customers. When the customer is an agent you can assign other customers.',
      action: 'Make {{name}} agent',
    },
    contactInfo: 'Contact information',
    tag: 'Agent tag',
    revokeBtn: 'Revoke agent rights',
    assignedCustomers: 'assigned customers',
    allCustomers: 'all customers',
    makeSuperAgent: 'Make super agent',
    makeNormalAgent: 'Make normal agent',
    assignedIntroduction:
      'At this moment this agent can assume the identity of <1>{{levelName}}</1>.',
    normalAgent: 'When making this agent a Super agent all customers can be assumed.',
    successAgent: 'Customer is successfully promoted to agent',
    successSuperAgent: 'Customer is successfully promoted to superagent',
    successRevokeAgent: 'Agent rights are revoked',
    successRevokeSuperAgent: 'Super agent rights are revoked',
    addCustomer: 'Add',
    deleteCustomer: 'Remove',
    revoke: {
      title: 'Are you sure?',
      confirmMessage:
        'You are going to revoke the Agent rights for this Shopify customer, are you sure?',
      submit: 'Yes, Revoke rights',
    },
  },
  customers: {
    added: 'Assigned',
    singular: 'customer',
    plural: 'customers',
    assigned: 'Assigned customers',
    add: 'Add customers',
    back: 'Back to assigned customers',
  },
  settings: {
    title: 'Settings',
    multipass: {
      title: 'Setup Multipass',
      description:
        'The Account Switcher requires the multipass secret in order to enable an agent to login as a customer',
      cardTitle: 'Multipass Secret',
      cardLink: 'Obtain your Multipass secret',
      replaceSecret: 'Replace Multipass secret',
      form: {
        label: 'Enter you new Multipass secret',
        helpText:
          'You can obtain the Multipass secret in the Checkout settings of your shop. You will have to enable multipass.',
      },
      display: {
        label: 'Current token',
      },
    },
    installation: {
      title: 'Installation instructions',
      description:
        'You need to install certain elements in your theme in order to use the app. NOTE: you should avoid that these files are overwritten by another process.',
      cardTitle: 'Liquid snippets',
      steps: {
        step1: 'Please click on the button below to install and configure the needed snippets.',
        snippetIntro: 'Include these code snippets into your <1>theme.liquid</1> file:',
        step2: 'Include this snippet before the closing tag',
        step3: 'Include this snippet right after the opening tag',
      },
      installSnippets: 'Install snippets',
      uninstallSnippets: 'Uninstall snippets',
    },
    configuration: {
      title: 'Configuration',
      description: 'Aditional configurations to adjust the behaviour of the app.',
      form: {
        createAccounts: {
          title: 'Allow creating new accounts',
          helpTexts: 'Allow agents to create new customer accounts.',
        },
        clearCartAfterSwitch: {
          title: 'Clear cart when switching to another customer',
          helpText:
            'Clear the cart when an agent switches to another customer. All items in the cart are then deleted.',
        },
        redirectUrl: {
          title: 'Redirect Url (after login)',
          helpText:
            'Enter the internal URL where an agent should be redirected to after switching to another customer.',
        },
      },
    },
  },
  manage: {
    title: 'Manage agents',
  },
  footer: {
    linkLearnMore: 'More information about the Account Switcher',
  },
};
