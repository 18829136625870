import React from 'react';
import { Link } from 'react-router-dom';

/**
 * This component is used in Shopify's Polaris AppProvider to override the behaviour of Polaris links so that they use client routing (react-router Link component).
 */

export const ClientRouterLink = ({ url, children, external, ...remainderProps }) => {
  const embeddedLinkMarkup = (
    <Link to={url} {...remainderProps}>
      {children}
    </Link>
  );

  const externalLinkMarkup = (
    <a
      href={url}
      className="Polaris-Link"
      target="_blank"
      rel="noopener noreferrer"
      {...remainderProps}
    >
      {children}
    </a>
  );

  return external ? externalLinkMarkup : embeddedLinkMarkup;
};
