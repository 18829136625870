import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import Firebase, { FirebaseContext } from './firebase';
import ClientRouter from './components/Routing/ClientRouter';
import ClientRoutePropagator from './components/Routing/ClientRoutePropagator';
import { ClientRouterLink } from './components/Routing/ClientRouterLink';
import { ToastProvider, ToastElement } from './contexts/ToastContext';
import { ModalProvider, ModalElement } from './contexts/ModalContext';
import Authorize from './components/Authorize/Authorize';
import { useAppApiKey } from './hooks/useAppApiKey';
import { useBrowserCheck } from './hooks/useBrowserCheck';
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';

// Get the shop parameter from the URL params appended by Shopify
const urlParams = new URLSearchParams(window.location.search);
const host = urlParams.get('host');
const shop = urlParams.get('shop');

export const App = () => {
  const { t } = useTranslation();
  const [loaded, appApiKey] = useAppApiKey(shop);
  const [incognitoLoaded, incognitoMode] = useBrowserCheck();

  if (!loaded || !incognitoLoaded)
    return (
      <AppProvider linkComponent={ClientRouterLink} i18n={enTranslations}>
        <LoadingSpinner text={t('generic.loading')} notInAppBridge />
      </AppProvider>
    );
  if (!appApiKey) return <div style={{ margin: '2rem' }}>{t('generic.errors.problemLoading')}</div>;

  const appBridgeConfig = {
    apiKey: appApiKey,
    host,
  };

  return (
    <AppProvider linkComponent={ClientRouterLink} i18n={enTranslations}>
      <BrowserRouter>
        <AppBridgeProvider config={appBridgeConfig}>
          <ClientRouter />
          <ClientRoutePropagator />
          <FirebaseContext.Provider value={new Firebase(incognitoMode)}>
            <ToastProvider>
              <ModalProvider>
                <ToastElement />
                <ModalElement />
                <Authorize shop={shop} />
              </ModalProvider>
            </ToastProvider>
          </FirebaseContext.Provider>
        </AppBridgeProvider>
      </BrowserRouter>
    </AppProvider>
  );
};
