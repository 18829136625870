import {
  Avatar,
  Card,
  Filters,
  Pagination,
  ResourceList,
  ResourceItem,
  TextStyle,
  TextContainer,
  Icon,
  Stack,
  Badge,
} from '@shopify/polaris';
import { CircleAlertMajor, CirclePlusMinor, CircleChevronLeftMinor } from '@shopify/polaris-icons';
import React, { useState, useCallback, useEffect } from 'react';
import { withFirebase } from '../../firebase';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';

const Overview = ({ firebase, agentId }) => {
  const { t } = useTranslation();
  const [queryValue, setQueryValue] = useState(null);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue(null);
    setQuery('');
  }, []);

  const [hasNextPage, setHasNextPage] = useState(false);
  const [firstCursor, setFirstCursor] = useState('');
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [lastCursor, setLastCursor] = useState('');
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [query, setQuery] = useState('');
  const [showAssignedCustomers, setShowAssignedCustomers] = useState(true);

  const handleSwitch = useCallback(
    () => setShowAssignedCustomers(!showAssignedCustomers),
    [showAssignedCustomers],
  );

  const resourceName = {
    singular: t('customers.singular'),
    plural: t('customers.plural'),
  };

  const customerData = useCallback(
    async (paginationParams) => {
      if (queryValue && query !== queryValue) return;

      setHasNextPage(false);
      setHasPreviousPage(false);
      setIsLoading(true);
      setHasError(false);
      const queryParams = new URLSearchParams();
      queryParams.append('shop', firebase.userData.shop);
      if (query) queryParams.append('query', query);
      if (paginationParams) {
        if (paginationParams['before']) queryParams.append('before', paginationParams['before']);
        if (paginationParams['after']) queryParams.append('after', paginationParams['after']);
      }

      try {
        const url = showAssignedCustomers
          ? `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/agent/${agentId}/assigned?${queryParams}`
          : `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/agent/${agentId}/unassigned?${queryParams}`;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-account-switcher': firebase.userData.token,
          },
        };

        const data = await fetch(url, options);
        if (data.status !== 200) return;
        const customerDataResponse = await data.json();

        // update states
        setCustomers(customerDataResponse.edges.map((edge) => edge.node));
        setHasNextPage(customerDataResponse.pageInfo.hasNextPage);
        setHasPreviousPage(customerDataResponse.pageInfo.hasPreviousPage);
        setFirstCursor(
          customerDataResponse.edges.length > 0 ? customerDataResponse.edges[0].cursor : '',
        );
        setLastCursor(
          customerDataResponse.edges.length > 0
            ? customerDataResponse.edges[customerDataResponse.edges.length - 1].cursor
            : '',
        );
      } catch (error) {
        console.log(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [firebase.userData, queryValue, query, agentId, showAssignedCustomers],
  );

  useEffect(() => {
    customerData();
  }, [customerData, firebase.userData.token]);

  const queryUpdate = useCallback((input) => {
    setQueryValue(input);
    setTimeout(() => setQuery(input), 1000);
  }, []);

  const goToPreviousPage = useCallback(
    () => customerData({ before: firstCursor }),
    [customerData, firstCursor],
  );
  const goToNextPage = useCallback(
    () => customerData({ after: lastCursor }),
    [customerData, lastCursor],
  );

  const addCustomer = useCallback(
    async (customerId) => {
      try {
        setIsLoading(true);

        const url = `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/agent/${agentId}/assign`;
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-account-switcher': firebase.userData.token,
          },
          body: JSON.stringify({
            shop: firebase.userData.shop,
            customerId: customerId,
          }),
        };

        const data = await fetch(url, options);
        if (data.status !== 200) throw Error('Something went wrong');

        // setIsAgent(true);
      } catch (error) {
        console.log(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
        customerData();
      }
    },
    [firebase.userData, agentId, customerData],
  );

  const deleteCustomer = useCallback(
    async (customerId) => {
      try {
        setIsLoading(true);

        const url = `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/agent/${agentId}/unassign`;
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-account-switcher': firebase.userData.token,
          },
          body: JSON.stringify({
            shop: firebase.userData.shop,
            customerId: customerId,
          }),
        };

        const data = await fetch(url, options);
        if (data.status !== 200) throw Error('Something went wrong');

        // setIsAgent(true);
      } catch (error) {
        console.log(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
        customerData();
      }
    },
    [firebase.userData, agentId, customerData],
  );

  const renderItem = (item) => {
    const { id, firstName, lastName, email } = item;
    const media = <Avatar customer size="medium" name={firstName + ' ' + lastName} />;

    const tag = config.customerTag.replace('$X', agentId);
    const legacyTag = config.legacy.customerTag.replace('$X', agentId);
    const isAssumable = item.tags.includes(tag) || item.tags.includes(legacyTag);
    const shortcutActions = [];
    let addedPill = null;
    if (isAssumable) {
      shortcutActions.push({
        content: t('agents.deleteCustomer'),
        onClick: () => deleteCustomer(id),
      });

      addedPill = <Badge status="success" children={t('customers.added')} />;
    } else {
      shortcutActions.push({
        content: t('agents.addCustomer'),
        onClick: () => addCustomer(id),
      });
    }

    const customerId = id.replace('gid://shopify/Customer/', '');
    return (
      <ResourceItem id={customerId} media={media} shortcutActions={shortcutActions}>
        <Stack>
          <Stack.Item>
            <TextStyle variation="strong">
              {firstName} {lastName}
            </TextStyle>
            <br />
            <TextStyle variation="subdued">{email}</TextStyle>
          </Stack.Item>
          <Stack.Item>{addedPill}</Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      disabled={isLoading}
      onQueryChange={queryUpdate}
      onQueryClear={handleQueryValueRemove}
    ></Filters>
  );

  const errorMarkup = <Error />;

  const actions = [];
  if (showAssignedCustomers) {
    actions.push({
      icon: CirclePlusMinor,
      disabled: isLoading,
      content: t('customers.add'),
      onAction: handleSwitch,
    });
  } else {
    actions.push({
      icon: CircleChevronLeftMinor,
      disabled: isLoading,
      content: t('customers.back'),
      onAction: handleSwitch,
    });
  }

  return (
    <React.Fragment>
      <Card>
        <Card.Section
          title={showAssignedCustomers ? t('customers.assigned') : t('customers.add')}
          actions={actions}
        >
          {hasError && errorMarkup}
          {!hasError && (
            <ResourceList
              resourceName={resourceName}
              items={customers}
              loading={isLoading}
              renderItem={renderItem}
              filterControl={filterControl}
            />
          )}
        </Card.Section>
        {!hasError && (
          <Card.Section subdued>
            <div className="navigation">
              <Pagination
                hasPrevious={hasPreviousPage}
                onPrevious={goToPreviousPage}
                hasNext={hasNextPage}
                onNext={goToNextPage}
              />
            </div>
          </Card.Section>
        )}
      </Card>
    </React.Fragment>
  );
};

const Error = () => {
  const { t } = useTranslation();
  return (
    <div className="center hasError">
      <TextContainer>
        <p>
          <Icon source={CircleAlertMajor} color="critical" />
        </p>
        <p>
          <TextStyle variation="negative" children={t('agents.error')} />
        </p>
      </TextContainer>
    </div>
  );
};

export default withFirebase(Overview);
