import {
  Avatar,
  Badge,
  Card,
  Filters,
  Pagination,
  ResourceList,
  ResourceItem,
  TextStyle,
  TextContainer,
  Stack,
  Icon,
} from '@shopify/polaris';
import { CircleAlertMajor } from '@shopify/polaris-icons';
import React, { useState, useCallback, useEffect } from 'react';
import { withFirebase } from '../../firebase';
import { useTranslation } from 'react-i18next';
import './Overview.css';
import { config } from '../../config';

const Overview = ({ firebase, agentsOnly }) => {
  const { t } = useTranslation();
  const [queryValue, setQueryValue] = useState(null);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue(null);
    setQuery('');
  }, []);

  const [hasNextPage, setHasNextPage] = useState(false);
  const [firstCursor, setFirstCursor] = useState('');
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [lastCursor, setLastCursor] = useState('');
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [query, setQuery] = useState('');

  const resourceName = {
    singular: t('agents.singular'),
    plural: t('agents.plural'),
  };

  const customerData = useCallback(
    async (paginationParams) => {
      if (queryValue && query !== queryValue) return;

      setHasNextPage(false);
      setHasPreviousPage(false);
      setIsLoading(true);
      setHasError(false);
      const queryParams = new URLSearchParams();
      queryParams.append('shop', firebase.userData.shop);
      if (agentsOnly) queryParams.append('agents', 'true');
      if (query) queryParams.append('query', query);
      if (paginationParams) {
        if (paginationParams['before']) queryParams.append('before', paginationParams['before']);
        if (paginationParams['after']) queryParams.append('after', paginationParams['after']);
      }

      try {
        const url = `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/customers?${queryParams}`;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-account-switcher': firebase.userData.token,
          },
        };

        const data = await fetch(url, options);
        if (data.status !== 200) return;
        const customerDataResponse = await data.json();

        // update states
        setCustomers(customerDataResponse.edges.map((edge) => edge.node));
        setHasNextPage(customerDataResponse.pageInfo.hasNextPage);
        setHasPreviousPage(customerDataResponse.pageInfo.hasPreviousPage);
        setFirstCursor(
          customerDataResponse.edges.length > 0 ? customerDataResponse.edges[0].cursor : '',
        );
        setLastCursor(
          customerDataResponse.edges.length > 0
            ? customerDataResponse.edges[customerDataResponse.edges.length - 1].cursor
            : '',
        );
      } catch (error) {
        console.log(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [firebase.userData, queryValue, query, agentsOnly],
  );

  useEffect(() => {
    customerData();
  }, [customerData, firebase.userData.token]);

  const queryUpdate = useCallback((input) => {
    setQueryValue(input);
    setTimeout(() => setQuery(input), 1000);
  }, []);

  const goToPreviousPage = useCallback(
    () => customerData({ before: firstCursor }),
    [customerData, firstCursor],
  );
  const goToNextPage = useCallback(
    () => customerData({ after: lastCursor }),
    [customerData, lastCursor],
  );

  const renderItem = (item) => {
    const { id, firstName, lastName, email } = item;
    const media = <Avatar customer size="medium" name={firstName + ' ' + lastName} />;

    const isAgent =
      item.tags.includes(config.agentTag) || item.tags.includes(config.legacy.agentTag);
    const isSuperAgent =
      item.tags.includes(config.superAgentTag) || item.tags.includes(config.legacy.superAgentTag);

    const agentPill =
      isAgent || isSuperAgent ? (
        <Badge status={isSuperAgent ? 'success' : 'info'}>
          {isSuperAgent ? t('agents.superAgent') : t('agents.agent')}
        </Badge>
      ) : null;

    const customerId = id.replace('gid://shopify/Customer/', '');
    return (
      <ResourceItem id={customerId} media={media} url={`/manage/agents/${customerId}`}>
        <Stack alignment="center">
          <Stack.Item fill>
            <TextStyle variation="strong">
              {firstName} {lastName}
            </TextStyle>
            <br />
            <TextStyle variation="subdued">{email}</TextStyle>
          </Stack.Item>
          <Stack.Item>{agentPill}</Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      disabled={isLoading}
      onQueryChange={queryUpdate}
      onQueryClear={handleQueryValueRemove}
    ></Filters>
  );

  const errorMarkup = <Error />;

  return (
    <Card>
      <Card.Section>
        {hasError && errorMarkup}
        {!hasError && (
          <ResourceList
            resourceName={resourceName}
            items={customers}
            loading={isLoading}
            renderItem={renderItem}
            filterControl={filterControl}
          />
        )}
      </Card.Section>
      {!hasError && (
        <Card.Section subdued>
          <div className="navigation">
            <Pagination
              hasPrevious={hasPreviousPage}
              onPrevious={goToPreviousPage}
              hasNext={hasNextPage}
              onNext={goToNextPage}
            />
          </div>
        </Card.Section>
      )}
    </Card>
  );
};

const Error = () => {
  const { t } = useTranslation();
  return (
    <div className="center hasError">
      <TextContainer>
        <p>
          <Icon source={CircleAlertMajor} color="critical" />
        </p>
        <p>
          <TextStyle variation="negative" children={t('agents.error')} />
        </p>
      </TextContainer>
    </div>
  );
};

export default withFirebase(Overview);
