import React from 'react';
import { Spinner, TextStyle } from '@shopify/polaris';
import './LoadingSpinner.css';

export const LoadingSpinner = ({ text, notInAppBridge }) => {
  const noAppBridgeClass = notInAppBridge ? ' not-in-app-bridge' : '';

  return (
    <div className={`loading-spinner${noAppBridgeClass}`}>
      <Spinner size="large" color="inkLightest" />
      {text && (
        <div className={`loading-spinner-text${noAppBridgeClass}`}>
          <TextStyle variation="subdued">{text}...</TextStyle>
        </div>
      )}
    </div>
  );
};
