import { Card, TextContainer, TextStyle, Stack, Button } from '@shopify/polaris';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

export const Intro = ({ agent, isLoading, actions, isSuperAgent }) => {
  const { t } = useTranslation();

  const { submit } = actions;

  return (
    <Card title={t('agents.details') + ': ' + agent.firstName + ' ' + agent.lastName}>
      <Card.Section>
        <Stack>
          <Stack.Item fill>
            <TextContainer>
              <p>
                <Trans
                  i18nKey="agents.assignedIntroduction"
                  values={{
                    levelName: isSuperAgent
                      ? t('agents.allCustomers')
                      : t('agents.assignedCustomers'),
                  }}
                >
                  At this moment this agent can assume the identity of
                  <TextStyle variation="strong">customers</TextStyle>.
                </Trans>
                <br />
                {t('agents.normalAgent')}
              </p>
            </TextContainer>
          </Stack.Item>
          <Stack.Item>
            <Button
              primary={!isSuperAgent}
              onClick={submit}
              loading={isLoading}
              children={isSuperAgent ? t('agents.makeNormalAgent') : t('agents.makeSuperAgent')}
            />
          </Stack.Item>
        </Stack>
      </Card.Section>
    </Card>
  );
};
