import { useCallback, useEffect, useState } from 'react';

// Check if we're in Incognito mode as we have to deal different
// with the Persistence there
// https://stackoverflow.com/questions/2909367/can-you-determine-if-chrome-is-in-incognito-mode-via-a-script
export const useBrowserCheck = () => {
  const [incognitoLoaded, setIncognitoLoaded] = useState(false);
  const [incognitoMode, setIncognitoMode] = useState(undefined);

  const checkBrowser = useCallback(async () => {
    try {
      const isIncognito = await new Promise((resolve, reject) => {
        const fs = window.RequestFileSystem || window.webkitRequestFileSystem;
        if (!fs) reject('Check incognito failed');
        else
          fs(
            window.TEMPORARY,
            100,
            () => resolve(false),
            () => resolve(true),
          );
      });

      setIncognitoMode(isIncognito);
    } catch (error) {
      setIncognitoMode(true);
    } finally {
      setIncognitoLoaded(true);
    }
  }, []);

  // eslint-disable-next-line
  useEffect(() => checkBrowser(), []);

  return [incognitoLoaded, incognitoMode];
};
