import { Card, TextContainer } from '@shopify/polaris';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const Introduction = () => {
  const { t } = useTranslation();

  return (
    <Card title={t('agents.title')}>
      <Card.Section>
        <TextContainer>
          <Trans i18nKey="agents.introduction">
            Agents can switch between identities. An agent can assume the identity of the
            <strong>assigned customers</strong>. You can also make this agent a Super agent, then
            all customers can be assumed (other agents cannot be assumed).
          </Trans>
        </TextContainer>
      </Card.Section>
    </Card>
  );
};
