import { Button, ButtonGroup, Card, Layout, TextContainer, TextStyle } from '@shopify/polaris';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

export const PromoteToAgent = ({ agent, actions, isLoading }) => {
  const { t } = useTranslation();
  const { submit } = actions;

  return (
    <Layout.Section>
      <Card title={t('agents.promote.title', { name: `${agent.firstName} ${agent.lastName}` })}>
        <Card.Section>
          <TextContainer>
            <p>
              <Trans
                i18nKey={'agents.promote.description'}
                values={{ name: `${agent.firstName} ${agent.lastName}` }}
              >
                In order to assign customers you will have to make
                <TextStyle variation="strong">agentName</TextStyle> an agent. This will add a tag to
                the customer which indicates that his customer may assume other customers. When the
                customer is an agent you can assign other customers.
              </Trans>
            </p>
            <ButtonGroup>
              <Button
                primary
                loading={isLoading}
                onClick={submit}
                children={t('agents.promote.action', {
                  name: `${agent.firstName} ${agent.lastName}`,
                })}
              />
              <Button plain children={t('generic.moreInfo')} />
            </ButtonGroup>
          </TextContainer>
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};
