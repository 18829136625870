import { TextContainer, TextField } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const EditForm = ({ data, onPropertyUpdated, invalidFieldMessages }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TextContainer spacing="tight">
        <div>
          <TextField
            requiredIndicator={true}
            label={
              <label className="settings-fields-label">{t('settings.multipass.form.label')}</label>
            }
            helpText={t('settings.multipass.form.helpText')}
            value={data.multipassToken}
            onChange={(newValue) => onPropertyUpdated('multipassToken', newValue)}
            error={invalidFieldMessages['multipassToken']}
          />
        </div>
      </TextContainer>
    </React.Fragment>
  );
};
