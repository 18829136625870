import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Button, ButtonGroup, Card, Form } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { AppConfigContext } from '../../../contexts/AppConfigContext/AppConfigContext';
import { ActionsWithHelpLink } from '../../Help/ActionsWithHelpLink';
import { AppConfig } from '../../../types/AppConfig';
import { EditForm } from './EditForm';
import { Display } from './Display';

export const Configuration = () => {
  const { t } = useTranslation();
  const { config, saveConfigToDb } = useContext(AppConfigContext);

  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [invalidFieldMessages, setInvalidFieldMessages] = useState({});

  const [configData, setConfigData] = useState();
  const [draftConfigData, setDraftConfigData] = useState();

  const enterEditMode = useCallback(() => {
    setDraftConfigData({
      allowCreateAccount: configData.allowCreateAccount,
      clearCartAfterSwitch: configData.clearCartAfterSwitch,
      redirectUrl: configData.redirectUrl,
    });
    setEditMode(true);
  }, [configData]);

  const exitEditMode = useCallback(() => {
    setEditMode(false);
    setInvalidFieldMessages({});
  }, []);

  const handlePropertyUpdated = useCallback((key, value) => {
    setDraftConfigData((currentData) => ({
      ...currentData,
      [key]: value,
    }));

    // reset validation
    setInvalidFieldMessages({});
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsSaving(true);
    try {
      const saveSuccess = await saveConfigToDb(AppConfig.Generic.id, {
        allowCreateAccount: draftConfigData.allowCreateAccount,
        clearCartAfterSwitch: draftConfigData.clearCartAfterSwitch,
        redirectUrl: draftConfigData.redirectUrl,
      });
      if (saveSuccess) {
        setEditMode(false);
        setConfigData({
          allowCreateAccount: draftConfigData.allowCreateAccount,
          clearCartAfterSwitch: draftConfigData.clearCartAfterSwitch,
          redirectUrl: draftConfigData.redirectUrl,
        });
        exitEditMode();
      }
    } finally {
      setIsSaving(false);
    }
  }, [draftConfigData, exitEditMode, saveConfigToDb]);

  useEffect(() => {
    if (config[AppConfig.Generic.id]) {
      setConfigData({
        allowCreateAccount: config[AppConfig.Generic.id]?.allowCreateAccount || false,
        clearCartAfterSwitch: config[AppConfig.Generic.id]?.clearCartAfterSwitch || false,
        redirectUrl: config[AppConfig.Generic.id]?.redirectUrl || '',
      });
    }
  }, [config]);

  return (
    <Form preventDefault onSubmit={handleSubmit}>
      <Card>
        <Card.Section>
          {editMode ? (
            <EditForm
              data={draftConfigData}
              onPropertyUpdated={handlePropertyUpdated}
              invalidFieldMessages={invalidFieldMessages}
            />
          ) : (
            <Display data={configData} />
          )}
        </Card.Section>
        <Card.Section subdued>
          {editMode ? (
            <ActionsWithHelpLink
              actions={
                <ButtonGroup>
                  <Button
                    children={t('generic.cancel')}
                    onClick={exitEditMode}
                    disabled={isSaving}
                  />
                  <Button primary submit children={t('generic.save')} loading={isSaving} />
                </ButtonGroup>
              }
            />
          ) : (
            <ActionsWithHelpLink
              actions={<Button children={t('generic.edit')} onClick={enterEditMode} />}
            />
          )}
        </Card.Section>
      </Card>
    </Form>
  );
};
