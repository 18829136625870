import { Badge, Button, Card, Link, TextContainer, TextStyle } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';

const { customerTag } = config;

export const Info = ({ agent, isLoading, actions }) => {
  const { t } = useTranslation();

  const { submit } = actions;

  return (
    <Card title={t('agents.details')}>
      <Card.Section>
        <TextContainer>
          <p>
            <TextStyle variation="subdued" children={agent.firstName + ' ' + agent.lastName} />
          </p>
        </TextContainer>
      </Card.Section>
      <Card.Section title={t('agents.contactInfo')}>
        <TextContainer>
          <p>
            <Link external url={`mailto:${agent.email}`}>
              {agent.email}
            </Link>
          </p>
        </TextContainer>
      </Card.Section>
      <Card.Section title={t('agents.tag')}>
        <TextContainer>
          <p>
            <Badge
              status="info"
              children={customerTag.replace('$X', agent.id.replace('gid://shopify/Customer/', ''))}
            />
          </p>
        </TextContainer>
      </Card.Section>
      <Card.Section>
        <Button destructive loading={isLoading} onClick={submit} children={t('agents.revokeBtn')} />
      </Card.Section>
    </Card>
  );
};
