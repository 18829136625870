import { Card, TextContainer } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ManageIntroduction = () => {
  const { t } = useTranslation();

  return (
    <Card title={t('agents.manageTitle')}>
      <Card.Section>
        <TextContainer>{t('agents.manageIntroduction')}</TextContainer>
      </Card.Section>
    </Card>
  );
};
