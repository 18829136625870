import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Button, Card, TextContainer, TextStyle, Tooltip } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import { useTranslation, Trans } from 'react-i18next';
import { withFirebase } from '../../../firebase';
import { AppConfigContext } from '../../../contexts/AppConfigContext/AppConfigContext';
import ToastContext from '../../../contexts/ToastContext/ToastContext';
import { AppConfig } from '../../../types/AppConfig';
import copy from 'copy-text-to-clipboard';
import './index.css';

const Installation = ({ firebase }) => {
  const { dispatchToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const { config, saveConfigToDb } = useContext(AppConfigContext);
  const [isSaving, setIsSaving] = useState(false);
  const [snippetsInstalled, setSnippetsInstalled] = useState(false);

  useEffect(() => {
    if (config[AppConfig.Generic.id]) {
      setSnippetsInstalled(config[AppConfig.Generic.id].snippetsInstalled);
    }
  }, [config]);

  const handleCopyToClipboard = (string) => {
    copy(string);
    dispatchToast(t('generic.copiedToClipboard'));
  };

  const handleInstallUninstall = useCallback(async () => {
    setIsSaving(true);
    try {
      const url = !snippetsInstalled
        ? `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/snippets/install`
        : `${process.env.REACT_APP_CLOUD_EU_FUNCTIONS_URI}/shopifyAdmin/snippets/uninstall`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-account-switcher': firebase.userData.token,
        },
        body: JSON.stringify({
          shop: firebase.userData.shop,
        }),
      };

      const data = await fetch(url, options);
      if (data.status !== 200) throw Error('Something went wrong');

      // update config
      const saveSuccess = await saveConfigToDb(AppConfig.Generic.id, {
        snippetsInstalled: !snippetsInstalled,
      });
      if (saveSuccess) {
        setSnippetsInstalled(true);
      }
    } catch (error) {
      setIsSaving(false);
      dispatchToast(t('generic.errors.serverError'), { isError: true });
      console.log(error);
    } finally {
      setSnippetsInstalled(!snippetsInstalled);
      setIsSaving(false);
    }
  }, [firebase.userData, saveConfigToDb, snippetsInstalled]);

  // set snippets
  const snippet1 = `{% render 'account-switcher_head' %}`;
  const snippet2 = `{% render 'account-switcher_open-customers-listing' %}`;

  return (
    <Card>
      <Card.Header title={t('settings.installation.cardTitle')} />
      <Card.Section>
        <TextContainer>
          <p>1. {t('settings.installation.steps.step1')}</p>
          <p>
            <Button
              primary={!snippetsInstalled}
              destructive={snippetsInstalled}
              loading={isSaving}
              onClick={handleInstallUninstall}
              children={
                snippetsInstalled
                  ? t('settings.installation.uninstallSnippets')
                  : t('settings.installation.installSnippets')
              }
            />
          </p>
        </TextContainer>
      </Card.Section>
      <Card.Section>
        <TextContainer>
          <p>
            <Trans i18nKey="settings.installation.steps.snippetIntro">
              Include these code snippets into your
              <TextStyle variation="code">theme.liquid</TextStyle>
              file:
            </Trans>
          </p>
          <p>
            2. {t('settings.installation.steps.step2')}{' '}
            <TextStyle variation="code">&lt;/head&gt;</TextStyle>
            <span className="codeSnippet">
              <TextStyle variation="code">
                <span className="snippet">{snippet1}</span>
                <Tooltip content={t('generic.copyToClipboard')} preferredPosition="above">
                  <Button
                    plain
                    icon={DuplicateMinor}
                    size="slim"
                    accessibilityLabel={t('generic.copyToClipboard')}
                    onClick={() => handleCopyToClipboard(snippet1)}
                  />
                </Tooltip>
              </TextStyle>
            </span>
          </p>
          <p>
            3. {t('settings.installation.steps.step3')}{' '}
            <TextStyle variation="code">&lt;body&gt;</TextStyle>
            <span className="codeSnippet">
              <TextStyle variation="code">
                <span className="snippet">{snippet2}</span>
                <Tooltip content={t('generic.copyToClipboard')} preferredPosition="above">
                  <Button
                    plain
                    icon={DuplicateMinor}
                    size="slim"
                    accessibilityLabel={t('generic.copyToClipboard')}
                    onClick={() => handleCopyToClipboard(snippet2)}
                  />
                </Tooltip>
              </TextStyle>
            </span>
          </p>
        </TextContainer>
      </Card.Section>
    </Card>
  );
};

export default withFirebase(Installation);
